<template>
	<Menu actual="/acerca-de" class="mt-6" />

	<div class="py-16 overflow-hidden">
		<div class="max-w-7xl mx-auto px-4 space-y-8 sm:px-6 lg:px-8">
			<div class="text-base max-w-prose mx-auto lg:max-w-none">
				<h2 class="text-sm text-morado font-semibold tracking-wide uppercase">Contexto</h2>
				<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-azul sm:text-4xl">Acerca de</p>
			</div>
			<div class="relative z-10 text-base max-w-prose mx-auto lg:max-w-5xl lg:mx-0 lg:pr-72">
				<p class="text-lg text-gray-500">
					En todas las épocas los adultos han enfrentado diferentes retos y dificultades al educar a  niñas y niños. Hoy en día, <span class="font-semibold text-morado-dark">elevar la calidad de la educación sexual</span>, constituye uno de esos grandes retos.
				</p>
			</div>
			<div class="lg:grid lg:grid-cols-2 lg:gap-8 lg:items-start">
				<div class="relative z-10">
					<div class="prose prose-indigo text-gray-500 mx-auto lg:max-w-none">
						<div class="space-y-4">
							<p>Problemas como el abuso y violencia sexual, los embarazos adolescentes, las infecciones de transmisión sexual etc.   son  reflejo de una deficiente y limitada educación sexual, por lo que se requiere del compromiso y trabajo conjunto de padres de familia, instituciones y maestros para elevar la calidad de la educación sexual e impulsar la transformación social.</p>
							<p> Actualmente, durante la infancia, están expuestos a mucha más información de todo tipo que hace algunos años; viven bombardeados de mensajes sexuales a través de cualquier medio de comunicación; tienen acceso a diferentes culturas, modos de vida, etc. Factores que en conjunto ejercen gran influencia en la forma de pensar, en el estilo de vida y comportamiento del individuo, incluyendo el manejo y expresión de la sexualidad, que sin duda es una de las áreas donde son más notorios estos cambios y motivo de preocupación para muchos adultos. </p>
							<p>Al vivir en un mundo tan cambiante los niños deben estar bien preparados, encontrar mejores respuestas acerca de si mismos y sobre el mundo que los rodea y así contar con más herramientas que los haga capaces de tomar decisiones acertadas, elegir estilos de vida sanos y ser responsables de su propio proyecto de vida.</p>
						</div>

						<img class="mx-auto mt-4 h-60 rounded-md shadow" :src="require('@/assets/acerca-de/mama-nina.jpg')" alt="" />
						</div>
				</div>
				<div class="mt-12 relative text-base max-w-prose mx-auto lg:mt-0 lg:max-w-none">
					<svg class="absolute top-0 right-0 -mt-20 -mr-20 lg:top-auto lg:right-auto lg:bottom-1/2 lg:left-1/2 lg:mt-0 lg:mr-0 xl:top-0 xl:right-0 xl:-mt-20 xl:-mr-20" width="404" height="384" fill="none" viewBox="0 0 404 384" aria-hidden="true">
						<defs>
							<pattern id="bedc54bc-7371-44a2-a2bc-dc68d819ae60" x="0" y="0" width="20" height="20" patternUnits="userSpaceOnUse">
								<rect x="0" y="0" width="4" height="4" class="text-gray-200" fill="currentColor" />
							</pattern>
						</defs>
						<rect width="404" height="384" fill="url(#bedc54bc-7371-44a2-a2bc-dc68d819ae60)" />
					</svg>
					<blockquote class="relative bg-white rounded-lg shadow-lg">
						<div class="rounded-t-lg px-6 py-8 sm:px-10 sm:pt-10 sm:pb-8">
							<div class="text-base font-semibold text-azul">
								<AcademicCapIcon class="w-6 h-6 inline-block mb-1 mr-1" />
								EXPERIENCIA
							</div>
							<div class="text-sm text-gray-700 font-normal mt-4 prose prose-indigo ml-2">
								<ul class="space-y-3">
									<li>Conferencias y cursos de educación sexual a padres de familia y maestros desde hace más de 20 años</li>
									<li>Talleres con más de 5,000 niños.</li>
									<li>Autora del programa de educación sexual: "SOMOS DIFERENTES" para preescolar y primaria publicado por   Editorial Trillas.</li>
									<li>CONAFE QRO. Implementó el programa de preescolar en las 575 comunidades del estado.</li>
									<li>Asesoría en más de 80 escuelas. Querétaro, México, D.F. Irapuato, San Juan del Río, Celaya, Cortazar, León.</li>
									<li>Talleres con niños de preescolar y primaria en comunidades de Huimilpan.</li>
									<li>Talleres con niñas de 6 a 17 años de las casas hogar: Hogares Providencia, Puerta Abierta, La rosa de lima, la Alegría de los niños, Niñas y niños de México</li>
									<li>Ponente en el Foro Gesell: Sexualidad, Homosexualidad y cultura. En Cholula, Puebla 2009.</li>
									<li>Ponente en el Foro Crecer con experiencia, organizado por la Organización Mundial de Educación preescolar (OMS).</li>
									<li>Capacitación al personal docente de los 9 CAIC (Centros de atención infantil comunitarios) a cargo del DIF Municipal de Querétaro.</li>
									<li>Ponente y participación en el VI Congreso AMSSAC de Salud Sexual en Noviembre 2010.</li>
								</ul>
							</div>
						</div>
						<cite class="relative flex items-center sm:items-start bg-azul rounded-b-lg not-italic py-5 px-6 sm:py-5 sm:pl-12 sm:pr-10 sm:mt-10">
							<div class="relative rounded-full border-2 border-white sm:absolute sm:top-0 sm:transform sm:-translate-y-1/2">
								<img class="w-12 h-12 sm:w-20 sm:h-20 rounded-full bg-indigo-300" :src="require('@/assets/acerca-de/adriana-torres.jpg')" alt="Adriana Torres Herrera" />
							</div>
							<span class="relative ml-4 text-white text-opacity-50 font-semibold leading-6 sm:ml-24 sm:pl-1">
								<p class="text-white font-semibold sm:inline">Adriana Torres</p>
								{{ ' ' }}
								<p class="sm:inline">Fundadora de Cuéntame Cómo</p>
							</span>
						</cite>
					</blockquote>
				</div>
			</div>
		</div>
	</div>


	<div class="py-6 xl:py-10 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden">
		<div class="max-w-max lg:max-w-7xl mx-auto">
			<div class="relative z-10 mb-8 md:mb-2 md:px-6">
				<div class="text-base max-w-prose lg:max-w-none">
				<p class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-vino sm:text-4xl">¿Cómo lo hacemos?</p>
				</div>
			</div>
			<div class="relative">
				<div class="relative md:bg-white md:p-6">
				<div class="lg:grid lg:grid-cols-2 lg:gap-6">
					<div class="prose prose-indigo text-gray-500 lg:max-w-none space-y-4">
					<p>Sabemos que es un tema a veces difícil para los adultos, y optan por evadirlo o posponerlo,  por lo que nos esmeramos  por  desarrollar material educativo claro y sencillo  que facilita la educación sexual oportuna e integral. Es ideal para abordar el tema a las diferentes edades, para que niñas y niños crezcan con conceptos claros sobre el cuerpo y su funcionamiento, al mismo tiempo que fortalecen elementos importantes para tener un futuro sano y feliz.</p>
					<p>Damos fin a las pláticas  esporádicas sobre este tema, con un programa que garantiza la continuidad, para impactar en el desarrollo y construyan una base sólida, con información verdadera y tengan   elementos para  ser críticos de lo que ven y oyen y  contrarrestar los efectos de información nociva que existe en el entorno. Se abre la comunicación  y desarrolla la confianza, para acompañar y guiar a niñas y niños  durante su desarrollo.</p>
					<p>Para cumplir con nuestro propósito, optamos por material digital, para que sea  accesible en cualquier momento, es decir, útil para quien desea planear la educación, o como guía ante preguntas impredecibles que hacen niñas y niños y muchas veces no sabemos como responder.</p>
					</div>
					<div class="mt-6 prose prose-indigo text-gray-500 lg:mt-0 space-y-4">
					<p>Todos los materiales están diseñados tomando en cuenta los intereses y curiosidades propias de cada edad para ir ampliando y profundizando en la información conforme crecen y  lo necesitan.</p>
					<p>Cada módulo  consta de dos elementos : un libro  descargable en cualquier dispositivo, que  contiene la información que los adultos deben dar y  el acceso a  una serie de  juegos interactivos como rompecabezas, sopa de letras, memoria, etc para reforzar los conocimientos.</p>
					<p>Con esta metodología resolvemos dos puntos, por un lado la información que se debe dar, de acuerdo a la edad, y por otro, con el uso de los juegos, se lleva a cabo  la repetición necesaria para que cada pequeño se apropie de la información.</p>
					</div>
				</div>
				</div>
			</div>
		</div>
  	</div>


	<div class="bg-white overflow-hidden">
		<div class="relative max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
		<div class="hidden lg:block bg-gray-50 absolute top-0 bottom-0 left-3/4 w-screen" />
		<div class="mx-auto text-base max-w-prose lg:grid lg:grid-cols-2 lg:gap-8 lg:max-w-none">
			<div>
			<h2 class="text-base text-morado font-semibold tracking-wide uppercase">Qué mejor que niñas y niños aprendan jugando</h2>
			<h3 class="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-cyan sm:text-4xl">¿Porqué juegos interactivos?</h3>
			</div>
		</div>
		<div class="mt-8 lg:grid lg:grid-cols-2 lg:gap-8">
			<div class="relative lg:row-start-1 lg:col-start-2">
			<div class="relative text-base mx-auto max-w-prose lg:max-w-none">
				<figure>
				<div class="aspect-w-12 aspect-h-7 lg:aspect-none">
					<img class="rounded-lg shadow-lg object-cover object-center" :src="require('@/assets/acerca-de/child.jpg')" alt="Niña jugando" width="1184" height="1376" />
				</div>
				<!-- <figcaption class="mt-3 flex text-sm text-gray-500">
					<CameraIcon class="flex-none w-5 h-5 text-gray-400" aria-hidden="true" />
					<span class="ml-2">Photograph by Marcus O’Leary</span>
				</figcaption> -->
				</figure>
			</div>
			</div>
			<div class="mt-8 lg:mt-0">
			<div class="text-base max-w-prose mx-auto lg:max-w-none">
				<p class="text-lg text-gray-500">El juego es un medio de aprendizaje natural, que niñas y niños utilizan espontáneamente, para entender su entorno y realidad, sin embargo, con los avances tecnológicos se han desarrollado nuevas formas de entretenimiento como los juegos interactivos,  que podemos aprovechar y trasladar  al ámbito educativo pues conllevan grandes beneficios en el aprendizaje:</p>
			</div>
			<div class="mt-5 prose prose-indigo text-gray-500 mx-auto lg:max-w-none lg:row-start-1 lg:col-start-1">
				<ul>
				<li>Existen estímulos visuales y auditivos que hacen más ágil el aprendizaje.</li>
				<li>Se estimulan habilidades lecto escritoras, digitales, visoespacial.</li>
				<li>El interés por el juego, sumado al interés propio de este tema, ayudan a  activar la atención y la memoria, factores indispensables para aprender.</li>
				<li>El usuario es  actor  en el proceso de aprendizaje, no solo  un receptor de la información.</li>
				<li>Cada usuario  crea su propia estrategia para la resolución de problemas (juego) y alcanzar el éxito.</li>
				<li>Se respeta el ritmo de aprendizaje de cada usuario.</li>
				<li>Al recibir retroalimentación de repuestas  correctas o incorrectas, existe la autoevaluación, comprueban cuánto han aprendido,  lo que motiva al usuario a seguir con el juego  hasta hacer suyos los conceptos.</li>
				<li>Los padres / maestros tienen oportunidad de guiar el aprendizaje al participar en la misma actividad, además de abrir la comunicación en temas tan importantes.</li>
				<li>Se puede jugar  con varias personas lo que favorece el intercambio de ideas.</li>
				<li>Es una herramienta disponible en cualquier momento.</li>
				<li>Promueve el trabajo colaborativo tan necesario en este tema  entre padres y maestros.</li>
				<li>Al trabajar juntos padres y maestros trabajamos por  un ambiente protector y bienestar de niñas y niños.</li>
				<li>En un tema tan relevante y a veces difícil de abordar ,que  con los juegos logramos que aprendan mientras juegan, es decir, resulta entretenido  y divertido.  rompe el prejuicio de ser un tema malo  y se  promueve una percepción positiva hacia la sexualidad.</li>
				</ul>


				<div class="text-morado font-semibold text-lg">Objetivos</div>
				<ul>
					<li>Lograr el desarrollo de competencias, para que niños y niñas así como los y las adolescentes estén mejor preparados para planear su futuro.</li>
					<li>Combatir de manera preventiva el  embarazo adolescente y sus consecuencias como la  deserción escolar, problemas de salud etc.</li>
					<li>Cumplir con el derecho de niños y niñas a recibir educación sexual integral.</li>
					<li>Crear un círculo virtuoso que ayude a romper el círculo de la pobreza.</li>
				</ul>
			</div>
			</div>
		</div>
		</div>
	</div>
</template>

<script>
// import { AcademicCapIcon } from '@heroicons/vue/outline'
import Menu from '@/components/core/Menu'

export default {
	components: {
		Menu
	}
}
</script>

